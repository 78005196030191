<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
    style="min-height: 60vh"
  >
    <b-modal
      ref="action-modal"
      hide-footer
      :title="currentModal.title"
      size="lg"
    >
      <validation-observer ref="mymodal" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleActionSubmit">
          <b-overlay
            opacity="0.17"
            blur="1rem"
            :show="isModalLoading"
            rounded="md"
            variant="secondary"
          >
            <b-form-group class="mb-0" label="Note" label-for="action-note">
              <template slot="label">
                Note <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Note"
                rules="required"
              >
                <b-form-textarea id="action-note" v-model="currentModal.note" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <template v-if="showTaskForm == true">
              <b-form-group
                class="mt-1"
                label="Task Name"
                label-for="task-title"
              >
                <template slot="label">
                  Task Name <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Task Name"
                  rules="required"
                >
                  <b-form-input
                    id="task-title"
                    v-model="currentModal.task.taskTitle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="mt-1"
                label="Description"
                label-for="task-description"
              >
                <validation-provider #default="{ errors }" name="Description">
                  <b-form-textarea
                    id="task-description"
                    v-model="currentModal.task.taskDescription"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="mt-1"
                label="Task Priority"
                label-for="task-priority"
              >
                <template slot="label">
                  Task Priority <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Task Priority"
                  rules="required"
                >
                  <b-form-select
                    v-model="currentModal.task.taskPriority"
                    :options="currentModal.task.taskPriorityOptions"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                class="mt-1"
                label="Assignee"
                label-for="task-assignee"
              >
                <template slot="label">
                  Asignee <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Assignee"
                  rules="required"
                >
                  <vue-autosuggest
                    :suggestions="currentModal.task.taskAssigneeOptions"
                    :limit="5"
                    v-model="currentModal.task.taskAssigneeName"
                    id="autosuggest__input"
                    :input-props="assigneeInputProps"
                    @input="getAssigneeBySearchPhrase"
                    @selected="onAssigneeSelected"
                  >
                    <template slot-scope="{ suggestion }">
                      <span
                        >{{ suggestion.item.firstname }}
                        {{ suggestion.item.lastname }}</span
                      >
                    </template>
                  </vue-autosuggest>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="mt-1" label="Due Date" label-for="due-date">
                <template slot="label">
                  Due Date <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Due Date"
                  rules="required"
                >
                  <b-form-datepicker
                    id="due-date"
                    v-model="currentModal.task.taskDueDate"
                    :min="minDueDate"
                  ></b-form-datepicker>
                  <small class="text-danger">{{
                    errors[0]
                  }}</small></validation-provider
                ></b-form-group
              >
            </template>
          </b-overlay>

          <b-button
            type="submit"
            variant="primary"
            class="ml-0 mt-75"
            :disabled="invalid || isModalLoading"
          >
            <b-spinner
              v-if="isModalLoading"
              label="Loading..."
              small
              class="mr-50"
            />
            <feather-icon v-else icon="CheckIcon" class="mr-50" />
            <span class="align-middle">Submit</span>
          </b-button>
          <!-- <b-button
        v-if="currentModal.type == 3 "
        @click.prevent="handleAddNewTaskClick"
        class="ml-1 mt-75"
        variant="success"
        ><feather-icon icon="PlusIcon" class="mr-50" />New Task</b-button
      > -->
        </b-form>
      </validation-observer>
    </b-modal>
    <div v-if="risk" class="card">
      <div class="card-header pb-50">
        <div class="w-100 row justify-content-start align-items-start">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-md-8 col-sm-12 h-auto pr-3"
          >
            <h4 class="mb-50 font-weight-bold">
              {{ risk.name == null ? "" : risk.name }}
            </h4>
            <p class="mb-0">
              {{ risk.description == null ? "" : risk.description }}
            </p>
          </div>
          <div class="col-md-4 col-sm-12">
            <div
              class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end border rounded px-1 py-75"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr
                  class="cursor-pointer"
                  @click="
                    () => {
                      scrollToSectionRef = 'assets-sec';
                    }
                  "
                >
                  <th class="pb-50">
                    <span class="font-weight-bold">Assets</span>
                  </th>
                  <td class="pb-50">
                    <b-badge pill variant="light-warning">{{
                      assets.length || 0
                    }}</b-badge>
                  </td>
                </tr>

                <tr
                  class="cursor-pointer"
                  @click="
                    () => {
                      scrollToSectionRef = 'vuls-sec';
                    }
                  "
                >
                  <th class="pb-50">
                    <span class="font-weight-bold">Vulnerabilities</span>
                  </th>
                  <td class="pb-50">
                    <b-badge pill variant="light-warning">{{
                      vulnerabilities.length || 0
                    }}</b-badge>
                  </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <span class="font-weight-bold">Action</span>
                  </th>
                  <td class="pb-50">
                    <b-dropdown
                      v-if="
                        risk &&
                        risk != null &&
                        risk.action_select_box_text &&
                        risk.action_select_box_text != null
                      "
                      :text="risk.action_select_box_text"
                      :variant="
                        getActionColor(
                          risk.risk_actions && risk.risk_actions.length > 0
                            ? risk.risk_actions[risk.risk_actions.length - 1]
                                .type
                            : 0
                        )
                      "
                      size="sm"
                    >
                      <b-dropdown-item
                        ><b-button
                          size="sm"
                          block
                          variant="dark"
                          @click="handleTransferClick"
                          >Transfer
                          <feather-icon
                            class="cursor-pointer ml-50"
                            icon="TruckIcon" /></b-button
                      ></b-dropdown-item>

                      <b-dropdown-item
                        ><b-button
                          size="sm"
                          variant="danger"
                          block
                          @click="handleAvoidClick"
                          >Avoid
                          <feather-icon
                            class="cursor-pointer ml-50"
                            icon="SlashIcon" /></b-button
                      ></b-dropdown-item>

                      <b-dropdown-item
                        ><b-button
                          size="sm"
                          block
                          variant="success"
                          @click="handleAcceptClick"
                          >Accept
                          <feather-icon
                            class="cursor-pointer ml-50"
                            icon="CheckCircleIcon" /></b-button
                      ></b-dropdown-item>

                      <b-dropdown-item
                        ><b-button
                          size="sm"
                          block
                          variant="warning"
                          @click="handleMitigateClick"
                          >Mitigate
                          <feather-icon
                            class="cursor-pointer ml-50"
                            icon="MinusCircleIcon" /></b-button
                      ></b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </table>
              <!-- <div
                @click="
                  () => {
                    scrollToSectionRef = 'assets-sec';
                  }
                "
              >
                <risk-mitigation-item
                  variant="primary"
                  title="Assets"
                  :value="assets.length"
                  :query="{}"
                  :isDisabled="true"
                />
              </div>

              <div class="mr-1"></div>
              <div
                @click="
                  () => {
                    scrollToSectionRef = 'vuls-sec';
                  }
                "
              >
                <risk-mitigation-item
                  variant="warning"
                  title="Vulnerabilities"
                  :value="vulnerabilities.length"
                  :query="{}"
                  :isDisabled="true"
                />
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <b-tabs class="mt-1" v-model="activeTab" fill>
        <b-tab title="General" lazy>
          <general
            :riskRegister="riskRegister"
            :assets="assets"
            :vulnerabilities="vulnerabilities"
            :threat="threat"
            :scrollToSectionRef="scrollToSectionRef"
            :resetRef="
              () => {
                scrollToSectionRef = null;
              }
            "
          />
        </b-tab>
        <b-tab title="Controls" lazy>
          <controls
            :registerId="$route.params.registerId"
            :reloadParent="load"
          />
        </b-tab>
        <b-tab title="Tasks" lazy>
          <tasks :registerId="$route.params.riskId" />
        </b-tab>
        <b-tab title="Discussions" lazy>
          <discussions :registerId="$route.params.riskId" />
        </b-tab>
        <b-tab title="Logs" lazy>
          <logs :registerId="$route.params.riskId" />
        </b-tab>
      </b-tabs>
    </div>
  </b-overlay>
</template>

<script>
import {
  BTab,
  BTabs,
  BOverlay,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BModal,
  BFormGroup,
  BForm,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import General from "./sections/General.vue";
import Controls from "./sections/Controls.vue";
import Discussions from "./sections/Discussions.vue";
import RiskMixins from "@/mixins/RiskMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
import Logs from "./sections/Logs.vue";
import RiskMitigationItem from "@/views/NewDashboard/sections/RiskAssessment/components/RiskMitigationItem.vue";
import Tasks from "./sections/Tasks.vue";

import { VueAutosuggest } from "vue-autosuggest";

export default {
  mixins: [RiskMixins, ResponseMixins],
  components: {
    BTabs,
    BTab,
    General,
    Controls,
    Discussions,
    BOverlay,
    Logs,
    RiskMitigationItem,
    Tasks,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BModal,

    BFormGroup,
    BForm,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormTextarea,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
    ValidationProvider,
    ValidationObserver,
    required,
    VueAutosuggest,
    BBadge,
    BSpinner,
  },

  data() {
    return {
      scrollToSectionRef: null,
      activeTab: 0,
      showOverlay: false,
      isModalLoading: false,
      risk: null,
      totalAssetsLength: 0,
      totalThreatsLength: 0,
      totalVulnerabilitiesLength: 0,
      assets: [],
      vulnerabilities: [],
      threat: null,
      riskRegister: {
        impact_score: 0,
        impact_value: 0,
        likelihood_value: 0,
        residual_impact_score: 0,
        residual_impact_value: 0,
        residual_likelihood_value: 0,
        residual_risk_value: 0,
        residual_threat_value: 0,
        residual_vulnerability_value: 0,
        risk_value: 0,
        threat_value: 0,
        vulnerability_value: 0,
        risk_statement: "",
        treatment_plan: "",
        asset_risks: null,
      },

      showTaskForm: false,
      minDueDate: null,
      currentModal: {
        title: "",
        note: "",
        task: {
          taskTitle: null,
          taskAssignee: null,
          taskDueDate: null,
          taskDescription: null,
          taskAssigneeOptions: [],
          taskAssigneeName: null,
          taskPriority: null,
          taskPriorityOptions: [
            { value: null, text: "Select Task Priority" },
            { value: 1, text: "Low" },
            { value: 2, text: "Medium" },
            { value: 3, text: "High" },
          ],
        },

        type: null,
        risk_id: null,
        isBulkSelection: false,
      },
      assigneeInputProps: {
        class: "form-control",
        placeholder: "Search..",
      },
    };
  },

  created() {
    this.setActiveTabFromQuery();
  },

  watch: {
    "$route.query.tabIndex": "setActiveTabFromQuery",
  },

  mounted() {
    this.load();
  },

  methods: {
    setActiveTabFromQuery() {
      const queryParams = this.$route.query;
      if ("tabIndex" in queryParams) {
        this.activeTab = parseInt(queryParams.tabIndex, 10);
      }
    },

    load() {
      this.$router.replace({ query: null });
      // this.getRiskDetailsAndSetData();
      this.getRiskRegisterDetailsAndSetData(this.$route.params.riskId);
      this.setMinDueDate();
    },

    handleActionSubmit() {
      this.isModalLoading = true;

      this.createRiskAction(this.currentModal)
        .then((res) => {
          this.load();
          this.hideModal();
          this.handleResponse(res);
          //Resetting the current modal state
          this.resetModalOptions();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.isModalLoading = false;
        });
    },

    setMinDueDate() {
      let date = new Date();
      date.setDate(date.getDate() + 1);
      this.minDueDate = date;
    },

    getAssigneeBySearchPhrase(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/users`,
          params: { search: searchPhrase, limit: 5 },
        };
        this.timeout = setTimeout(() => {
          this.$http(options)
            .then((res) => {
              this.currentModal.task.taskAssigneeOptions = [];
              let assignees = res.data.data.data;
              this.currentModal.task.taskAssigneeOptions.push({
                name: "assignees",
                data: assignees,
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.currentModal.task.taskAssigneeOptions = [];
        this.currentModal.task.taskAssignee = null;
        this.currentModal.task.taskAssigneeName = null;
      }
    },
    onAssigneeSelected(assignee) {
      this.currentModal.task.taskAssignee = assignee.item;
      this.currentModal.task.taskAssigneeName = `${assignee.item.firstname} ${assignee.item.lastname}`;
    },

    showModal() {
      if (this.currentModal.type == 4) {
        this.currentModal.task.taskTitle = this.risk.name + " Mitigate Task";
        this.currentModal.task.taskDescription = this.risk.treatment_plan
          ? this.risk.treatment_plan
          : "";
      } else {
        this.currentModal.task.taskTitle = null;
        this.currentModal.task.taskDescription = null;
      }
      this.$refs["action-modal"].show();
    },
    hideModal() {
      this.$refs["action-modal"].hide();
    },

    resetModalOptions() {
      const newTask = {
        taskTitle: null,
        taskAssignee: null,
        taskDueDate: null,
        taskDescription: null,
        taskAssigneeOptions: [],
        taskAssigneeName: null,
        taskPriority: null,
        taskPriorityOptions: [
          { value: null, text: "Select Task Priority" },
          { value: 1, text: "Low" },
          { value: 2, text: "Medium" },
          { value: 3, text: "High" },
        ],
      };
      this.$set(this.currentModal, "task", newTask);
      this.$set(this.currentModal, "note", "");
      this.$set(this.currentModal, "type", null);
      this.$set(this.currentModal, "risk_id", null);
      this.$set(this.currentModal, "isBulkSelection", false);
    },
    // handleAddNewTaskClick() {
    //   this.showTaskForm = false;
    // },

    handleTransferClick() {
      this.resetModalOptions();
      this.currentModal.title = "Transfer";
      this.currentModal.type = 2;
      this.currentModal.risk_id = this.$route.params.riskId;
      this.showTaskForm = true;
      this.showModal();
    },
    handleAvoidClick() {
      this.resetModalOptions();
      this.currentModal.title = "Avoid";
      this.currentModal.type = 1;
      this.currentModal.risk_id = this.$route.params.riskId;
      this.showTaskForm = true;
      this.showModal();
    },
    handleAcceptClick() {
      this.resetModalOptions();
      this.currentModal.title = "Accept";
      this.currentModal.type = 3;
      this.currentModal.risk_id = this.$route.params.riskId;
      this.showTaskForm = false;
      this.showModal();
    },
    handleMitigateClick() {
      this.resetModalOptions();
      this.currentModal.title = "Mitigate";
      this.currentModal.type = 4;
      this.currentModal.risk_id = this.$route.params.riskId;
      this.showTaskForm = true;
      this.showModal();
    },

    getActionPastTenseValue(value) {
      switch (value) {
        case 1:
          return "Avoided";
        case 2:
          return "Transfered";
        case 3:
          return "Accepted";
        case 4:
          return "Mitigated";
        default:
          return "Avoided";
      }
    },

    getActionValue(value) {
      switch (value) {
        case 1:
          return "Avoid";
        case 2:
          return "Transfer";
        case 3:
          return "Accept";
        case 4:
          return "Mitigate";
        default:
          return "Avoid";
      }
    },

    getActionColor(value) {
      console.log(value);
      switch (value) {
        case 1:
          return "danger";
        case 2:
          return "dark";
        case 3:
          return "success";
        case 4:
          return "warning";
        default:
          return "info";
      }
    },

    scrollToSection(ref) {
      console.log(ref);
      this.$refs[ref].scrollIntoView({ behavior: "smooth" });
    },

    getRiskRegisterDetailsAndSetData(registerId) {
      this.showOverlay = true;
      this.getRiskRegisterDetails(registerId)
        .then((res) => {
          console.log("Register Details: ", res);
          const register = res.data.data;
          if (register.risk && register.risk != null) {
            const newRisk = register.risk;
            Object.assign(newRisk, {
              risk_actions: register.risk_actions || [],
            });

            if (register.risk_actions.length > 0) {
              Object.assign(newRisk, {
                action_select_box_text: this.getActionPastTenseValue(
                  parseInt(
                    register.risk_actions[register.risk_actions.length - 1]
                      .type,
                    10
                  )
                ),
              });
            } else {
              Object.assign(newRisk, {
                action_select_box_text: "Select Action",
              });
            }
            this.risk = newRisk;
          }
          const newRiskRegister = {}; // A single soruce of Data
          // Getting values from the Risk Assessment Response Object
          if (
            (register.risk_assessment_response &&
              register.risk_assessment_response != null &&
              Object.keys(register.risk_assessment_response).length > 0) ||
            (register.asset_risk &&
              register.asset_risk != null &&
              Object.keys(register.asset_risk).length > 0)
          ) {
            Object.keys(this.riskRegister).forEach((key) => {
              // First Checks for the keys in the Main Object
              // The Keys in this object are of topmost priority
              if (register[key] && register[key] != null) {
                newRiskRegister[key] = register[key];
              }
              // Else Checks for the keys in the risk_assessment_response
              else if (
                register.risk_assessment_response &&
                register.risk_assessment_response != null &&
                register.risk_assessment_response[key] &&
                register.risk_assessment_response[key] != null
              ) {
                newRiskRegister[key] = register.risk_assessment_response[key];
              }
              // Else Checks for the keys in the asset_risk
              else if (
                register.asset_risk &&
                register.asset_risk != null &&
                register.asset_risk[key] &&
                register.asset_risk[key] != null
              ) {
                newRiskRegister[key] = register.asset_risk[key];
              } else {
                newRiskRegister[key] = null;
              }
            });
            console.log("New Details: ", newRiskRegister);
            this.riskRegister = newRiskRegister;
          }

          if (
            register.relatedRiskAssets &&
            register.relatedRiskAssets != null
          ) {
            this.assets = register.relatedRiskAssets;
          }
          if (
            register &&
            register != null &&
            register.vulnerabilities &&
            register.vulnerabilities != null
          ) {
            this.vulnerabilities = register.vulnerabilities;
          }

          if (
            register &&
            register != null &&
            register.threat &&
            register.threat != null
          ) {
            this.threat = register.threat;
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    // getRiskDetailsAndSetData() {
    //   this.showOverlay = true;
    //   this.getRiskDetails(this.$route.params.registerId)
    //     .then((res) => {
    //       console.log("res", res.data.data);
    //       this.risk = res.data.data;
    //       this.totalAssetsLength =
    //         res.data.data.asset_risks && res.data.data.asset_risks != null
    //           ? res.data.data.asset_risks.filter(
    //               (asset) => asset.asset !== null
    //             ).length
    //           : 0;

    //       this.totalVulnerabilitiesLength =
    //         res.data.data.vulnerabilities.length;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.handleError(err);
    //     })
    //     .finally(() => {
    //       this.showOverlay = false;
    //     });
    // },
  },
};
</script>

<style></style>
