<template>
  <b-overlay
    :show="showOverlay"
    opacity="0.17"
    blur="1rem"
    rounded="md"
    variant="secondary"
  >
    <div class="w-100 h-auto pt-50">
      <!-- <b-tooltip
        variant="warning"
        target="bulk-action-button"
        placement="top"
        v-show="disableBulkActionButtons"
        triggers="hover"
      >
        Select controls to enable bulk actions
      </b-tooltip>
      <b-tooltip
        variant="warning"
        target="adjust-residual-risk-button"
        v-show="disableAdjustResidualRisk"
        placement="top"
        triggers="hover"
      >
        Adjust residual risk is not available for "Not Implemented" controls
      </b-tooltip> -->
      <b-modal
        ref="residual-calculator-modal"
        hide-footer
        modal-class="modal-warning"
        centered
      >
        <template #modal-header="{}">
          <div class="d-flex align-items-center justify-content-between w-100">
            <h5 class="text-warning mb-0">
              <feather-icon icon="SlidersIcon" class="mr-50" /><span
                >Adjust Residual Risk</span
              >
            </h5>
          </div>
        </template>
        <residual-risk-calculator-view
          :closeAction="hideResidualCalculatorModal"
          :reloadParent="()=>{
            load()
            reloadParent()
          }"
        />
      </b-modal>
      <div
        class="w-100 d-flex align-items-center justify-content-between mb-50"
      >
        <div class="d-flex align-items-center justify-content-start">
          <b-dropdown
            id="bulk-action-button"
            text="Mark Selected as"
            variant="info"
            size="sm"
            class="mx-50"
            :disabled="disableBulkActionButtons"
          >
            <b-dropdown-item @click="handleBulkImplementationStatusChange(1)"
              >Implemented</b-dropdown-item
            >
            <b-dropdown-item @click="handleBulkImplementationStatusChange(2)"
              >Partially Implemented</b-dropdown-item
            >
            <b-dropdown-item @click="handleBulkImplementationStatusChange(3)"
              >Not Implemented</b-dropdown-item
            >
            <b-dropdown-item @click="handleBulkImplementationStatusChange(4)"
              >Planned</b-dropdown-item
            >
          </b-dropdown>

          <b-button
            id="adjust-residual-risk-button"
            class="mx-50"
            @click="showResidualCalculatorModal"
            size="sm"
            variant="warning"
            :disabled="disableAdjustResidualRisk"
          >
            <span class="text-center mr-50">Adjust Residual Risk</span>
            <feather-icon icon="SlidersIcon" />
          </b-button>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-form-checkbox
            v-model="showOnlyApplicableControls"
            name="Only Applicable Controls Checkbox"
            switch
            inline
          >
            Only Applicable Controls
          </b-form-checkbox>
        </div>
      </div>
      <table role="table" class="table table-condensed w-100">
        <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col">
              <b-form-checkbox
                v-model="allControlsSelected"
                @change="handleAllControlsSelectionChange"
                :value="true"
                :unchecked-value="false"
                class="custom-control-primary"
              >
              </b-form-checkbox>
            </th>
            <th role="columnheader" scope="col" class="text-start">Number</th>
            <th role="columnheader" scope="col">Control</th>
            <th role="columnheader" scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <template
            v-if="
              filteredControls &&
              filteredControls != null &&
              filteredControls.length > 0
            "
          >
            <template v-for="control in filteredControls">
              <tr
                role="row"
                :key="control._id"
                :class="[{ disabled: control.applicability_status !== 1 }]"
                :id="{
                  'not-applicable-row': control.applicability_status !== 1,
                }"
              >
                <td role="cell">
                  <div class="d-flex align-items-center justify-content-start">
                    <b-form-checkbox
                      v-model="control.selected"
                      :value="true"
                      :unchecked-value="false"
                      class="custom-control-primary"
                      :disabled="control.applicability_status !== 1"
                    >
                    </b-form-checkbox>
                  </div>
                </td>

                <td role="cell">
                  <div
                    class="w-100 d-flex align-items-center justify-content-start"
                  >
                    <b-badge
                      :disabled="control.applicability_status !== 1"
                      variant="danger"
                      >{{ control.control_no }}</b-badge
                    >
                  </div>
                </td>

                <td role="cell">
                  {{ control.name }}
                </td>
                <td role="cell">
                  <b-form-select
                    :disabled="control.applicability_status !== 1"
                    size="sm"
                    v-model="control.implementation_status"
                    :options="actionOptions"
                    @change="
                      (value) => {
                        handleImplementationStatusChange(value, control._id);
                      }
                    "
                    style="width: 170px"
                  />
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <empty-table-section title="Controls Empty">
              <template #content>
                <p class="text-center">
                  It seems there are no controls at the moment.
                </p>
              </template>
            </empty-table-section>
          </template>
        </tbody>
      </table>
    </div>
  </b-overlay>
</template>

<script>
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import {
  BFormCheckbox,
  BBadge,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BOverlay,
  BModal,
  BSidebar,
  BButton,
  BTooltip,
} from "bootstrap-vue";
import RiskMixins from "@/mixins/RiskMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
import ResidualRiskCalculatorView from "../components/ResidualRiskCalculatorView.vue";
export default {
  mixins: [RiskMixins, ResponseMixins],
  components: {
    EmptyTableSection,
    BFormCheckbox,
    BBadge,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BModal,
    ResidualRiskCalculatorView,
    BSidebar,
    BButton,
    BTooltip,
  },

  props: {
    registerId: {
      type: String,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      showOnlyApplicableControls: true,
      allControlsSelected: false,
      selectedValue: false,
      selectedAction: null,
      actionOptions: [
        // { value: null, text: "Select an Option" },
        { value: 1, text: "Implemented" },
        { value: 2, text: "Partially Implemented" },
        { value: 3, text: "Not Implemented" },
        { value: 4, text: "Planned" },
      ],

      showOverlay: false,
      controls: [],
    };
  },

  computed: {
    disableBulkActionButtons() {
      return !this.controls.some((c) => c.selected === true);
    },
    disableAdjustResidualRisk() {
      return this.controls.every((c) => c.implementation_status == 3);
    },

    filteredControls() {
      let filtered = this.controls;
      if (this.showOnlyApplicableControls) {
        filtered = filtered.filter((c) => c.applicability_status === 1);
      }
      return filtered.sort(
        (a, b) => a.applicability_status - b.applicability_status
      );
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getRiskControlsAndSetData(this.registerId);
    },

    showResidualCalculatorModal() {
      this.$refs["residual-calculator-modal"].show();
    },

    hideResidualCalculatorModal() {
      this.$refs["residual-calculator-modal"].hide();
    },

    resetBulkSelection() {
      this.allControlsSelected = false;
    },

    handleBulkImplementationStatusChange(value) {
      console.log("Bulk Action Value: ", value, this.controls);
      const newControlIds = this.controls
        .filter((e) => e.selected === true)
        .map((c) => c._id);
      const newData = {
        implementation_status: value,
        control_ids: newControlIds,
      };
      const riskId = this.$route.params.registerId;
      this.bulkUpdateRiskControlsAndSetData(riskId, newData);
    },

    handleImplementationStatusChange(newValue, controlId) {
      console.log("Implementation Status Change: ", controlId, newValue);
      const newData = {
        implementation_status: newValue,
      };
      const riskId = this.$route.params.registerId;
      this.updateRiskControlAndSetData(riskId, controlId, newData);
    },

    bulkUpdateRiskControlsAndSetData(riskId, data) {
      this.showOverlay = true;
      this.bulkUpdateRiskControls(riskId, data)
        .then((res) => {
          console.log("Bulk Control Update Res", res);
          this.handleResponse(res);
          this.resetBulkSelection();
          this.load();
          this.reloadParent();
          this.showResidualCalculatorModal();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    updateRiskControlAndSetData(riskId, controlId, data) {
      this.showOverlay = true;
      this.updateRiskControl(riskId, controlId, data)
        .then((res) => {
          console.log("Control Update Res", res);
          this.handleResponse(res);
          this.reloadParent()
          this.showResidualCalculatorModal();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleAllControlsSelectionChange(value) {
      if (value) {
        const newControls = this.controls.map((c) => {
          if (c.applicability_status === 1) {
            c.selected = true;
          }
          return c;
        });
        return newControls;
      } else {
        const newControls = this.controls.map((c) => {
          if (c.applicability_status === 1) {
            c.selected = false;
          }
          return c;
        });
        return newControls;
      }
    },

    getRiskControlsAndSetData(riskId) {
      this.showOverlay = true;
      this.getRiskControls(riskId)
        .then((res) => {
          console.log("Risk Controls: ", res);
          const newControls = res.data.data.map((c) => {
            c["selected"] = false;
            return c;
          });
          this.controls = newControls;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style></style>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}

tr.disabled {
  background-color: #f2f2f2;
}

[dir] .dark-layout tr.disabled {
  background-color: #241f2d;
}
</style>
