<template>
  <div class="card border-secondary shadow rounded text-center" style="width:180px;">
    <div class="card-body">
      <span
        class="b-avatar mb-1 rounded-circle"
        :class="'badge-light-' + variant"
        style="width: 76px; height: 76px"
        ><span class="b-avatar-custom"
          ><feather-icon :variant="variant" size="36" :icon="icon" /></span
      ></span>
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ title }}
        </h2>
        <span class="font-weight-bold">{{ subtitle }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      required: true,
      type: String,
    },
    variant: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
  },
};
</script>

<style>
</style>