<template>
  <div style="height: 100%" class="w-100 card shadow-none">
    <div class="w-100 h-auto pl-1">
      <!-- <h3 class="card-title mb-50">
        Comments
        <b-badge v-if="comments.length > 0" class="ml-50" pill variant="warning">{{
          comments.length
        }}</b-badge>
      </h3> -->
    </div>
    <div class="card-body px-0 py-50">
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showCommentLoading"
        rounded="md"
        variant="secondary"
      >
        <vue-perfect-scrollbar
          :settings="{
            minScrollbarLength: 80,
            swipeEasing: true,
            wheelSpeed: 1,
          }"
          style="max-height: 55vh"
          class="border rounded"
        >
          <div
            class="d-flex flex-column justify-content-between align-items-center px-1 py-1 rounded"
          >
            <template
              v-if="comments && comments != null && comments.length > 0"
            >
              <template v-for="comment in comments">
                <template
                  v-if="
                    comment.addedBy &&
                    comment.addedBy.user_id === $store.state.app.user.user_id
                  "
                >
                  <current-user-comment-card
                    :key="comment._id"
                    :firstName="comment.addedBy.firstname"
                    :lastName="comment.addedBy.lastname"
                    :dateTime="comment.created_at"
                    :message="comment.message"
                  />
                </template>
                <template v-else>
                  <user-comment-card
                    :key="comment._id"
                    :firstName="comment.addedBy.firstname"
                    :lastName="comment.addedBy.lastname"
                    :dateTime="comment.created_at"
                    :message="comment.message"
                  />
                </template>
              </template>
            </template>
            <template v-else>
              <div class="card w-100 mb-0 py-1">
                <div
                  class="d-flex flex-column align-items-center justify-content-center"
                >
                  <feather-icon icon="DatabaseIcon" size="32" class="mb-50" />
                  <h6 class="mb-25 font-weight-bolder">No Comments Found</h6>
                  <p class="mb-0" v-if="disableCreation === false">
                    You can add comments from the section below.
                  </p>
                </div>
              </div>
            </template>
          </div>
        </vue-perfect-scrollbar>
      </b-overlay>
      <div
        v-if="disableCreation === false"
        class="d-flex flex-column justify-content-start align-items-start pt-1"
      >
        <p class="mb-25 font-weight-bold">Write your comment</p>
        <!-- <div v-if="allowUserMention" ref="textareaCover" class="textarea-cover w-100" v-html="commentMention"> -->
        <template v-if="allowUserMention">
          <mention-input @input="handleComment" ref="mentionInput" />
          <!-- <Mentionable
            class="w-100"
            :keys="['@', '＠']"
            :items="items"
            insert-space
            omit-key
            @open="getUserSuggestions()"
            @search="getUserSuggestions($event)"
          >
            <b-form-textarea
              class="w-100"
              id="textarea"
              v-model="newComment"
              placeholder="Comment..."
              rows="3"
              :disabled="showCommentLoading"
            ></b-form-textarea>

            <template #item="{ item }">
              <div class="user">
                <span class="mx-1 font-weight-bold">
                  {{ item.label }}
                </span>
              </div>
            </template>

            <template #no-result>
              <div class="dim">
                {{ isUserSuggestionLoading ? "Loading..." : "No result" }}
              </div>
            </template>
          </Mentionable> -->
        </template>
        <template v-else>
          <b-form-textarea
            id="textarea"
            v-model="newComment"
            placeholder="Comment..."
            rows="3"
            :disabled="showCommentLoading"
          ></b-form-textarea>
        </template>

        <div class="w-100 d-flex align-items-center justify-content-end">
          <b-button
            class="mt-50"
            @click="handleAddNewCommentClick"
            variant="success"
            :disabled="
              newComment === null || newComment === '' || showCommentLoading
            "
          >
            <span v-if="showCommentLoading" class="align-middle"
              ><b-spinner type="border" class="mr-50" small></b-spinner
              ><span class="align-middle">Please Wait</span></span
            >
            <span v-else
              ><feather-icon icon="SendIcon" class="mr-50" /><span
                class="align-middle"
                >Send</span
              ></span
            >
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MentionInput from "./MentionInput";
import {
  BButton,
  BFormTextarea,
  BSpinner,
  BBadge,
  BOverlay,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import UtilsMixins from "@/mixins/UtilsMixins";
import FeatherIcon from "../@core/components/feather-icon/FeatherIcon.vue";
import CurrentUserCommentCard from "./CurrentUserCommentCard.vue";
import UserCommentCard from "./UserCommentCard.vue";
import { Mentionable } from "vue-mention";
import ResponseMixins from "@/mixins/ResponseMixins";
import UserMixins from "@/mixins/UserMixins";

Mentionable.methods.onKeyDown = function (e) {
  if (this.key) {
    if (e.key === "ArrowDown" || e.keyCode === 40) {
      this.selectedIndex++;
      if (this.selectedIndex >= this.displayedItems.length) {
        this.selectedIndex = 0;
      }
      this.cancelEvent(e);
    }
    if (e.key === "ArrowUp" || e.keyCode === 38) {
      this.selectedIndex--;
      if (this.selectedIndex < 0) {
        this.selectedIndex = this.displayedItems.length - 1;
      }
      this.cancelEvent(e);
    }
    if (
      (e.keyCode === 13 || e.keyCode === 9) &&
      this.displayedItems.length > 0
    ) {
      this.applyMention(this.selectedIndex);
      this.cancelEvent(e);
    }
    if (e.key === "Escape" || e.keyCode === 27) {
      this.closeMenu();
      this.cancelEvent(e);
    }
  }
};

export default {
  mixins: [ResponseMixins, UserMixins],
  components: {
    MentionInput,
    VuePerfectScrollbar,
    BButton,
    BFormTextarea,
    BSpinner,
    BBadge,
    BOverlay,
    FeatherIcon,
    CurrentUserCommentCard,
    UserCommentCard,
    Mentionable,
  },
  mixins: [UtilsMixins, UserMixins, ResponseMixins],
  data() {
    return {
      newComment: null,
      isUserSuggestionLoading: false,
      items: [],
    };
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    newCommentCallback: {
      type: Function,
      required: true,
    },
    showCommentLoading: {
      type: Boolean,
      required: true,
    },
    disableCreation: {
      type: Boolean,
      required: false,
      default: false,
    },
    reloadParent: {
      type: Function,
      required: false,
      default: () => {},
    },
    allowUserMention: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    // commentMention() {
    //   if (typeof this.newComment?.replaceAll !== "function") {
    //     return this.newComment;
    //   }
    //   const replaced =
    //     this.newComment
    //       ?.replaceAll("&", "&amp;")
    //       ?.replaceAll(">", "&gt;")
    //       ?.replaceAll("<", "&lt;") + "\n\n";
    //   const search = new RegExp(
    //     this.users
    //       .slice()
    //       .sort((a, b) => b.value?.length - a.value?.length)
    //       .map((user) => user.value)
    //       .join("|"),
    //     "g"
    //   );
    //   return replaced.replace(search, (match, offset) => {
    //     return `<span class="mention-str">${match}</span>`;
    //   });
    // },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  methods: {
    handleComment(comment) {
      const parsedComment = this.parseComment(comment.value);
      // console.log("Comment: ", parsedComment);
      this.newComment = parsedComment
    },

    parseComment(input) {
      let tempElement = document.createElement("div");
      tempElement.innerHTML = input;

      // Find all elements with the 'mention' class and replace their content with the data-id
      let mentions = tempElement.querySelectorAll(".mention");
      mentions.forEach((mention) => {
        mention.textContent = `{{${mention.getAttribute("data-id")}}}`;
      });

      // Return the text content without any HTML tags
      return tempElement.textContent || tempElement.innerText || "";
    },

    clearText() {
      this.newComment = null;
      this.$refs.mentionInput.clearText();
    },

    handleAddNewCommentClick() {
      this.newCommentCallback(this.newComment)
        .then((res) => {
          this.clearText();
        })
        .finally(() => {
          this.reloadParent();
        });
    },

    async getUserSuggestions(search = null) {
      this.isUserSuggestionLoading = true;
      this.getUsers({ search: search, limit: 5 })
        .then((res) => {
          console.log(res);
          this.items = res.data.data.data.map((u) => {
            return {
              value: `{{${u.email}}}`,
              label: `${u.firstname} ${u.lastname}`,
            };
          });
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.isUserSuggestionLoading = false;
        });
    },
  },
};
</script>
<style lang="css"></style>

<style lang="scss">
[id^="popover_"].tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;
    border-radius: 12px;

    .popover-inner {
      background: $color;
      color: black;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.mention-selected {
  background: #5b38bd !important;
  color: #f6f6f6;
}

.dim {
  padding: 6px 10px;
}
</style>
