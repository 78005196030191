<template>
  <div class="card-body">
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
      style="min-height: 60vh"
    >
      <div class="row justify-content-around">
        <div class="col-4">
          <div class="card" v-if="riskRegister && riskRegister != null">
            <div class="card-header mb-75 pb-0">
              <h5 class="font-weight-bold text-primary text-center m-auto">
                Risk Associated Values
              </h5>
            </div>
            <div
              class="card-body d-flex flex-column align-items-start jutsify-content-evenly border py-1"
            >
              <div
                class="w-100 d-flex flex-row align-items-center justify-content-between mb-1"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.risk_value &&
                  riskRegister.risk_value != null
                "
              >
                <p class="font-weight-bolder mb-0">Risk Value</p>
                <risk-value-badge
                  :getVariant="getMappedScoreVariant"
                  :getTextValue="getMappedScoreTextValue"
                  :value="parseInt(riskRegister.risk_value)"
                />
              </div>

              <div
                class="w-100 d-flex flex-row align-items-center justify-content-between mb-1"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.impact_score &&
                  riskRegister.impact_score != null
                "
              >
                <p class="font-weight-bolder mb-0">Impact Value</p>

                <risk-value-badge
                  :getVariant="getScoreVariant"
                  :getTextValue="getScoreTextValue"
                  :value="parseInt(riskRegister.impact_score)"
                />
              </div>

              <div
                class="w-100 d-flex flex-row align-items-center justify-content-between mb-1"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.likelihood_value &&
                  riskRegister.likelihood_value != null
                "
              >
                <p class="font-weight-bolder mb-0">Likelihood Value</p>

                <risk-value-badge
                  :getTextValue="getLikelihoodTextFromValue"
                  :value="parseInt(riskRegister.likelihood_value)"
                />
              </div>
              <div
                class="w-100 d-flex flex-row align-items-center justify-content-between mb-1"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.vulnerability_value &&
                  riskRegister.vulnerability_value != null
                "
              >
                <p class="font-weight-bolder mb-0">Vulnerability Value</p>

                <risk-value-badge
                  :getVariant="getScoreVariant"
                  :getTextValue="getScoreTextValue"
                  :value="parseInt(riskRegister.vulnerability_value)"
                />
              </div>

              <div
                class="w-100 d-flex flex-row align-items-center justify-content-between"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.threat_value &&
                  riskRegister.threat_value != null
                "
              >
                <p class="font-weight-bolder mb-0">Threat Value</p>
                <risk-value-badge
                  :getVariant="getScoreVariant"
                  :getTextValue="getScoreTextValue"
                  :value="parseInt(riskRegister.threat_value)"
                />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header mb-75 pb-0">
              <h5 class="font-weight-bold text-center text-primary m-auto">
                Residual Values
              </h5>
            </div>
            <div
              class="card-body d-flex flex-column align-items-start jutsify-content-evenly py-1 border"
            >
              <div
                class="w-100 d-flex flex-row align-items-center justify-content-between mb-1"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.residual_risk_value &&
                  riskRegister.residual_risk_value != null
                "
              >
                <p class="font-weight-bolder mb-0">Residual Risk Value</p>
                <risk-value-badge
                  :getVariant="getMappedScoreVariant"
                  :getTextValue="getMappedScoreTextValue"
                  :value="parseInt(riskRegister.residual_risk_value)"
                />
              </div>

              <div
                class="w-100 d-flex flex-row align-items-center justify-content-between mb-1"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.residual_impact_score &&
                  riskRegister.residual_impact_score != null
                "
              >
                <p class="font-weight-bolder mb-0">Residual Impact Value</p>
                <risk-value-badge
                  :getVariant="getScoreVariant"
                  :getTextValue="getScoreTextValue"
                  :value="parseInt(riskRegister.residual_impact_score)"
                />
              </div>

              <div
                class="w-100 d-flex flex-row align-items-center justify-content-between mb-1"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.residual_likelihood_value &&
                  riskRegister.residual_likelihood_value != null
                "
              >
                <p class="font-weight-bolder mb-0">Residual Likelihood Value</p>

                <risk-value-badge
                  :getTextValue="getLikelihoodTextFromValue"
                  :value="parseInt(riskRegister.residual_likelihood_value)"
                />
              </div>

              <div
                class="w-100 d-flex flex-row align-items-center justify-content-between"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.residual_vulnerability_value &&
                  riskRegister.residual_vulnerability_value != null
                "
              >
                <p class="font-weight-bolder">Residual Vulnerability Value</p>

                <risk-value-badge
                  :getVariant="getScoreVariant"
                  :getTextValue="getScoreTextValue"
                  :value="parseInt(riskRegister.residual_vulnerability_value)"
                />
              </div>

              <div
                class="w-100 d-flex flex-row align-items-center justify-content-between"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.residual_threat_value &&
                  riskRegister.residual_threat_value != null
                "
              >
                <p class="font-weight-bolder">Residual Threat Value</p>

                <risk-value-badge
                  :getVariant="getScoreVariant"
                  :getTextValue="getScoreTextValue"
                  :value="parseInt(riskRegister.residual_threat_value)"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="col-8">
          <div class="card">
            <!-- <div class="card-header">
            <h3 class="font-weight-bolder m-auto">Details</h3>
          </div> -->
            <div
              class="card-body d-flex flex-column align-items-start jutsify-content-evenly"
            >
              <div
                class="d-flex flex-column align-items-start justify-content-start mb-2 w-100"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.risk_statement &&
                  riskRegister.risk_statement != null
                "
              >
                <h6 class="text-primary">Statement</h6>
                <div
                  class="rounded border d-flex align-items-start justify-content-start w-100 p-1"
                >
                  <div
                    class="d-flex align-items-start justify-content-start w-100"
                  >
                    <p class="text-left font-weight-bold mb-0">
                      {{ riskRegister.risk_statement }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="d-flex flex-column align-items-start justify-content-start mb-2 w-100"
                v-if="threat && threat != null"
              >
                <h6 class="text-primary">Threat</h6>
                <div
                  class="rounded border d-flex align-items-start justify-content-start w-100 p-1"
                >
                  <div
                    class="d-flex flex-column align-items-start justify-content-start w-100"
                  >
                    <p class="text-left font-weight-bold mb-0">
                      {{ threat.name }}
                    </p>
                    <small class="mt-25">{{ threat.description }}</small>
                  </div>
                </div>
              </div>

              <div
                class="d-flex flex-column align-items-start justify-content-start mb-2 w-100"
                v-if="
                  riskRegister &&
                  riskRegister != null &&
                  riskRegister.treatment_plan &&
                  riskRegister.treatment_plan != null
                "
              >
                <h6 class="text-primary">Treatment Plan</h6>
                <div
                  class="rounded border d-flex align-items-start justify-content-start w-100 p-1"
                >
                  <div
                    class="d-flex flex-column align-items-start justify-content-start w-100"
                  >
                    <p class="text-left font-weight-bold mb-0">
                      {{ riskRegister.treatment_plan }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="w-100 card border" ref="vuls-sec">
                <div class="card-header py-75">
                  <h6 class="text-primary mb-0">Vulnerabilities</h6>
                </div>
                <table class="table table-borderless table-sm">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="vulnerabilities && vulnerabilities.length > 0"
                    >
                      <tr :key="vul._id" v-for="(vul, i) in vulnerabilities">
                        <td scope="row">
                          {{ i + 1 }}
                        </td>
                        <td scope="row">
                          <p class="mb-0 font-weight-bold">{{ vul.name }}</p>
                        </td>
                        <td>
                          <p class="mb-0 font-weight-bold">
                            {{ vul.description }}
                          </p>
                        </td>
                        <td>
                          <b-badge pill variant="light-warning">{{
                            vul.vulnerability_value
                          }}</b-badge>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <empty-table-section title="No Vulnerabilities">
                        <template #content>
                          <p class="text-center">
                            It seems like there are no vulnerabilities at the
                            moment.
                          </p>
                        </template>
                      </empty-table-section>
                    </template>
                  </tbody>
                </table>
              </div>

              <div class="w-100 card border" ref="assets-sec">
                <div class="card-header py-75">
                  <h6 class="text-primary mb-0">Assets</h6>
                </div>
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-if="assets && assets != null && assets.length > 0"
                    >
                      <tr :key="asset._id" v-for="(asset, index) in assets">
                        <th scope="row" style="max-width: 80px">
                          {{ index + 1 }}
                        </th>
                        <td>
                          <p class="mb-0 font-weight-bold">
                            {{ asset.asset_name }}
                          </p>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <empty-table-section title="No Assets">
                        <template #content>
                          <p class="text-center">
                            It seems like there are no assets at the moment.
                          </p>
                        </template>
                      </empty-table-section>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardText,
  BCardGroup,
  BButton,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BBadge,
  BSidebar,
  VBToggle,
  BFormSelect,
  BOverlay,
  BSpinner,
  BTable,
  BAvatar,
} from "bootstrap-vue";
import RiskMixins from "@/mixins/RiskMixins";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import ItemCard from "@/components/ItemCard.vue";
import RiskMitigationItem from "@/views/NewDashboard/sections/RiskAssessment/components/RiskMitigationItem.vue";
import RiskValueBadge from "../../components/RiskValueBadge.vue";
import ResponseMixins from "@/mixins/ResponseMixins";
export default {
  name: "RiskDetails",
  mixins: [RiskMixins, ResponseMixins],
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardGroup,
    BButton,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    BSidebar,
    BFormSelect,
    BOverlay,
    BSpinner,
    BTable,
    ItemCard,
    BAvatar,
    RiskMitigationItem,
    EmptyTableSection,
    RiskValueBadge,
  },
  props: {
    riskRegister: {
      type: Object,
      required: true,
    },

    assets: {
      type: Array,
      required: true,
    },
    vulnerabilities: {
      type: Array,
      required: true,
    },
    threat: {
      type: Object,
      required: true,
    },
    scrollToSectionRef: {
      type: String | null,
      required: true,
    },
    resetRef: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      showOverlay: false,
    };
  },

  watch: {
    scrollToSectionRef: {
      handler: function (newVal) {
        if (newVal && newVal != null) {
          this.scrollToSection(newVal);
          this.resetRef();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    // this.load();
  },

  methods: {
    // load() {
    //   this.getRiskRegisterDetailsAndSetData(this.$route.params.riskId);
    // },

    scrollToSection(ref) {
      this.$refs[ref].scrollIntoView({ behavior: "smooth" });
    },

    mapValue(value) {
      return this.mapRange(value, 0, 100, 1, 5);
    },

    // mapRange(value, inMin, inMax, outMin, outMax) {
    //   return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    // },

    mapRange(val, inMin, inMax, outMin, outMax) {
      return ((val - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    },

    // getRiskRegisterDetailsAndSetData(registerId) {
    //   this.showOverlay = true;
    //   this.getRiskRegisterDetails(registerId)
    //     .then((res) => {
    //       console.log("Register Details: ", res);
    //       const register = res.data.data;
    //       const newRiskRegister = {}; // A single soruce of Data
    //       // Getting values from the Risk Assessment Response Object
    //       if (
    //         (register.risk_assessment_response &&
    //           register.risk_assessment_response != null &&
    //           Object.keys(register.risk_assessment_response).length > 0) ||
    //         (register.asset_risk &&
    //           register.asset_risk != null &&
    //           Object.keys(register.asset_risk).length > 0)
    //       ) {
    //         Object.keys(this.riskRegister).forEach((key) => {
    //           if (register[key] && register[key] != null) {
    //             newRiskRegister[key] = register[key];
    //           } else if (
    //             register.risk_assessment_response &&
    //             register.risk_assessment_response != null &&
    //             register.risk_assessment_response[key] &&
    //             register.risk_assessment_response[key] != null
    //           ) {
    //             newRiskRegister[key] = register.risk_assessment_response[key];
    //           } else if (
    //             register.asset_risk &&
    //             register.asset_risk != null &&
    //             register.asset_risk[key] &&
    //             register.asset_risk[key] != null
    //           ) {
    //             newRiskRegister[key] = register.asset_risk[key];
    //           }
    //         });
    //         this.riskRegister = newRiskRegister;
    //       }

    //       if (
    //         register.relatedRiskAssets &&
    //         register.relatedRiskAssets != null
    //       ) {
    //         this.assets = register.relatedRiskAssets;
    //       }
    //       if (
    //         register.risk &&
    //         register.risk != null &&
    //         register.risk.vulnerabilities &&
    //         register.risk.vulnerabilities != null
    //       ) {
    //         this.vulnerabilities = register.risk.vulnerabilities;
    //       }

    //       if (
    //         register.risk &&
    //         register.risk != null &&
    //         register.risk.threat &&
    //         register.risk.threat != null
    //       ) {
    //         this.threat = register.risk.threat;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.handleError(err);
    //     })
    //     .finally(() => {
    //       this.showOverlay = false;
    //     });
    // },
    getLikelihoodTextFromValue(value) {
      if (value && value !== null) {
        if (value == 1) {
          return "Once in 5 years";
        } else if (value == 2) {
          return "Once in 2 years";
        } else if (value == 3) {
          return "Once a year";
        } else if (value == 4) {
          return "Once in Six months";
        } else if (value == 5) {
          return "Once in a month";
        } else {
          console.log("Unknown Value", value);
          return "Unknown Value";
        }
      }
    },

    getMappedScoreVariant(value) {
      const status = this.mapValue(Number(value));

      const newValue = Number(status);
      if (newValue >= 0 && newValue < 1) {
        return "light-success";
      } else if (newValue >= 1 && newValue < 2) {
        return "light-info";
      } else if (newValue >= 2 && newValue < 3) {
        return "light-primary";
      } else if (newValue >= 3 && newValue < 4) {
        return "light-warning";
      } else if (newValue >= 4 && newValue <= 5) {
        return "light-danger";
      } else {
        return "light-success";
      }
    },

    getMappedScoreTextValue(value) {
      const status = this.mapValue(Number(value));
      const newValue = Number(status);
      if (newValue >= 0 && newValue < 1) {
        return "Very Low";
      } else if (newValue >= 1 && newValue < 2) {
        return "Low";
      } else if (newValue >= 2 && newValue < 3) {
        return "Medium";
      } else if (newValue >= 3 && newValue < 4) {
        return "High";
      } else if (newValue >= 4 && newValue <= 5) {
        return "Very High";
      } else {
        return "Very Low";
      }
    },

    getScoreVariant(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return "light-success";
        case 2:
          return "light-info";
        case 3:
          return "light-primary";
        case 4:
          return "light-warning";
        case 5:
          return "light-danger";
        default:
          return "light-success";
      }
    },

    getScoreTextValue(status) {
      const newValue = Number(status);
      switch (newValue) {
        case 1:
          return "Very Low";
        case 2:
          return "Low";
        case 3:
          return "Medium";
        case 4:
          return "High";
        case 5:
          return "Very High";
        default:
          return "Very Low";
      }
    },
  },
};
</script>

<style></style>
