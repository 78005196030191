<template>
  <div
    class="card cursor-pointer m-0"
    style="min-width: 140px"
    :class="[`bg-light-${variant}`, `border-${variant}`]"
    @click="handleClickAction"
  >
    <div
      class="card-header border-bottom d-flex align-items-center justify-content-center pb-50"
    >
      <h5 class="mb-0 text-center">{{ title }}</h5>
    </div>
    <div class="card-body pt-75">
      <h5 class="mb-0 text-center">{{ value }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: "primary",
    },
    query: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: false,
      default: "/risk-register",
    },
    isDisabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  methods: {
    handleClickAction() {
      if (!this.isDisabled) {
        this.$router.push({
          path: this.path,
          query: this.query,
        });
      }
    },
  },
};
</script>

<style></style>
