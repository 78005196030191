export default {
  methods: {
    getLogs(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/logs/es`,
          params: params,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },
  },
};
