var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 card shadow-none",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"w-100 h-auto pl-1"}),_c('div',{staticClass:"card-body px-0 py-50"},[_c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showCommentLoading,"rounded":"md","variant":"secondary"}},[_c('vue-perfect-scrollbar',{staticClass:"border rounded",staticStyle:{"max-height":"55vh"},attrs:{"settings":{
          minScrollbarLength: 80,
          swipeEasing: true,
          wheelSpeed: 1,
        }}},[_c('div',{staticClass:"d-flex flex-column justify-content-between align-items-center px-1 py-1 rounded"},[(_vm.comments && _vm.comments != null && _vm.comments.length > 0)?[_vm._l((_vm.comments),function(comment){return [(
                  comment.addedBy &&
                  comment.addedBy.user_id === _vm.$store.state.app.user.user_id
                )?[_c('current-user-comment-card',{key:comment._id,attrs:{"firstName":comment.addedBy.firstname,"lastName":comment.addedBy.lastname,"dateTime":comment.created_at,"message":comment.message}})]:[_c('user-comment-card',{key:comment._id,attrs:{"firstName":comment.addedBy.firstname,"lastName":comment.addedBy.lastname,"dateTime":comment.created_at,"message":comment.message}})]]})]:[_c('div',{staticClass:"card w-100 mb-0 py-1"},[_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center"},[_c('feather-icon',{staticClass:"mb-50",attrs:{"icon":"DatabaseIcon","size":"32"}}),_c('h6',{staticClass:"mb-25 font-weight-bolder"},[_vm._v("No Comments Found")]),(_vm.disableCreation === false)?_c('p',{staticClass:"mb-0"},[_vm._v(" You can add comments from the section below. ")]):_vm._e()],1)])]],2)])],1),(_vm.disableCreation === false)?_c('div',{staticClass:"d-flex flex-column justify-content-start align-items-start pt-1"},[_c('p',{staticClass:"mb-25 font-weight-bold"},[_vm._v("Write your comment")]),(_vm.allowUserMention)?[_c('mention-input',{ref:"mentionInput",on:{"input":_vm.handleComment}})]:[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Comment...","rows":"3","disabled":_vm.showCommentLoading},model:{value:(_vm.newComment),callback:function ($$v) {_vm.newComment=$$v},expression:"newComment"}})],_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-end"},[_c('b-button',{staticClass:"mt-50",attrs:{"variant":"success","disabled":_vm.newComment === null || _vm.newComment === '' || _vm.showCommentLoading},on:{"click":_vm.handleAddNewCommentClick}},[(_vm.showCommentLoading)?_c('span',{staticClass:"align-middle"},[_c('b-spinner',{staticClass:"mr-50",attrs:{"type":"border","small":""}}),_c('span',{staticClass:"align-middle"},[_vm._v("Please Wait")])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SendIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Send")])],1)])],1)],2):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }