var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{staticStyle:{"min-height":"60vh"},attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('b-modal',{ref:"action-modal",attrs:{"hide-footer":"","title":_vm.currentModal.title,"size":"lg"}},[_c('validation-observer',{ref:"mymodal",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleActionSubmit.apply(null, arguments)}}},[_c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.isModalLoading,"rounded":"md","variant":"secondary"}},[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Note","label-for":"action-note"}},[_c('template',{slot:"label"},[_vm._v(" Note "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"action-note"},model:{value:(_vm.currentModal.note),callback:function ($$v) {_vm.$set(_vm.currentModal, "note", $$v)},expression:"currentModal.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),(_vm.showTaskForm == true)?[_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Task Name","label-for":"task-title"}},[_c('template',{slot:"label"},[_vm._v(" Task Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Task Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"task-title"},model:{value:(_vm.currentModal.task.taskTitle),callback:function ($$v) {_vm.$set(_vm.currentModal.task, "taskTitle", $$v)},expression:"currentModal.task.taskTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Description","label-for":"task-description"}},[_c('validation-provider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"task-description"},model:{value:(_vm.currentModal.task.taskDescription),callback:function ($$v) {_vm.$set(_vm.currentModal.task, "taskDescription", $$v)},expression:"currentModal.task.taskDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Task Priority","label-for":"task-priority"}},[_c('template',{slot:"label"},[_vm._v(" Task Priority "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Task Priority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.currentModal.task.taskPriorityOptions},model:{value:(_vm.currentModal.task.taskPriority),callback:function ($$v) {_vm.$set(_vm.currentModal.task, "taskPriority", $$v)},expression:"currentModal.task.taskPriority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Assignee","label-for":"task-assignee"}},[_c('template',{slot:"label"},[_vm._v(" Asignee "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Assignee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{attrs:{"suggestions":_vm.currentModal.task.taskAssigneeOptions,"limit":5,"id":"autosuggest__input","input-props":_vm.assigneeInputProps},on:{"input":_vm.getAssigneeBySearchPhrase,"selected":_vm.onAssigneeSelected},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',[_vm._v(_vm._s(suggestion.item.firstname)+" "+_vm._s(suggestion.item.lastname))])]}}],null,true),model:{value:(_vm.currentModal.task.taskAssigneeName),callback:function ($$v) {_vm.$set(_vm.currentModal.task, "taskAssigneeName", $$v)},expression:"currentModal.task.taskAssigneeName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Due Date","label-for":"due-date"}},[_c('template',{slot:"label"},[_vm._v(" Due Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Due Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"due-date","min":_vm.minDueDate},model:{value:(_vm.currentModal.task.taskDueDate),callback:function ($$v) {_vm.$set(_vm.currentModal.task, "taskDueDate", $$v)},expression:"currentModal.task.taskDueDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2)]:_vm._e()],2),_c('b-button',{staticClass:"ml-0 mt-75",attrs:{"type":"submit","variant":"primary","disabled":invalid || _vm.isModalLoading}},[(_vm.isModalLoading)?_c('b-spinner',{staticClass:"mr-50",attrs:{"label":"Loading...","small":""}}):_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Submit")])],1)],1)]}}])})],1),(_vm.risk)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pb-50"},[_c('div',{staticClass:"w-100 row justify-content-start align-items-start"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start col-md-8 col-sm-12 h-auto pr-3"},[_c('h4',{staticClass:"mb-50 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.risk.name == null ? "" : _vm.risk.name)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.risk.description == null ? "" : _vm.risk.description)+" ")])]),_c('div',{staticClass:"col-md-4 col-sm-12"},[_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end border rounded px-1 py-75"},[_c('table',{staticClass:"mt-2 mt-xl-0 w-100"},[_c('tr',{staticClass:"cursor-pointer",on:{"click":function () {
                    _vm.scrollToSectionRef = 'assets-sec';
                  }}},[_c('th',{staticClass:"pb-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Assets")])]),_c('td',{staticClass:"pb-50"},[_c('b-badge',{attrs:{"pill":"","variant":"light-warning"}},[_vm._v(_vm._s(_vm.assets.length || 0))])],1)]),_c('tr',{staticClass:"cursor-pointer",on:{"click":function () {
                    _vm.scrollToSectionRef = 'vuls-sec';
                  }}},[_c('th',{staticClass:"pb-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Vulnerabilities")])]),_c('td',{staticClass:"pb-50"},[_c('b-badge',{attrs:{"pill":"","variant":"light-warning"}},[_vm._v(_vm._s(_vm.vulnerabilities.length || 0))])],1)]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Action")])]),_c('td',{staticClass:"pb-50"},[(
                      _vm.risk &&
                      _vm.risk != null &&
                      _vm.risk.action_select_box_text &&
                      _vm.risk.action_select_box_text != null
                    )?_c('b-dropdown',{attrs:{"text":_vm.risk.action_select_box_text,"variant":_vm.getActionColor(
                        _vm.risk.risk_actions && _vm.risk.risk_actions.length > 0
                          ? _vm.risk.risk_actions[_vm.risk.risk_actions.length - 1]
                              .type
                          : 0
                      ),"size":"sm"}},[_c('b-dropdown-item',[_c('b-button',{attrs:{"size":"sm","block":"","variant":"dark"},on:{"click":_vm.handleTransferClick}},[_vm._v("Transfer "),_c('feather-icon',{staticClass:"cursor-pointer ml-50",attrs:{"icon":"TruckIcon"}})],1)],1),_c('b-dropdown-item',[_c('b-button',{attrs:{"size":"sm","variant":"danger","block":""},on:{"click":_vm.handleAvoidClick}},[_vm._v("Avoid "),_c('feather-icon',{staticClass:"cursor-pointer ml-50",attrs:{"icon":"SlashIcon"}})],1)],1),_c('b-dropdown-item',[_c('b-button',{attrs:{"size":"sm","block":"","variant":"success"},on:{"click":_vm.handleAcceptClick}},[_vm._v("Accept "),_c('feather-icon',{staticClass:"cursor-pointer ml-50",attrs:{"icon":"CheckCircleIcon"}})],1)],1),_c('b-dropdown-item',[_c('b-button',{attrs:{"size":"sm","block":"","variant":"warning"},on:{"click":_vm.handleMitigateClick}},[_vm._v("Mitigate "),_c('feather-icon',{staticClass:"cursor-pointer ml-50",attrs:{"icon":"MinusCircleIcon"}})],1)],1)],1):_vm._e()],1)])])])])])]),_c('b-tabs',{staticClass:"mt-1",attrs:{"fill":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab',{attrs:{"title":"General","lazy":""}},[_c('general',{attrs:{"riskRegister":_vm.riskRegister,"assets":_vm.assets,"vulnerabilities":_vm.vulnerabilities,"threat":_vm.threat,"scrollToSectionRef":_vm.scrollToSectionRef,"resetRef":function () {
              _vm.scrollToSectionRef = null;
            }}})],1),_c('b-tab',{attrs:{"title":"Controls","lazy":""}},[_c('controls',{attrs:{"registerId":_vm.$route.params.registerId,"reloadParent":_vm.load}})],1),_c('b-tab',{attrs:{"title":"Tasks","lazy":""}},[_c('tasks',{attrs:{"registerId":_vm.$route.params.riskId}})],1),_c('b-tab',{attrs:{"title":"Discussions","lazy":""}},[_c('discussions',{attrs:{"registerId":_vm.$route.params.riskId}})],1),_c('b-tab',{attrs:{"title":"Logs","lazy":""}},[_c('logs',{attrs:{"registerId":_vm.$route.params.riskId}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }