var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-100"},[_c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('validation-observer',{ref:"risk_assessment_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-0",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitClick.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Impact Score"}},[_c('template',{slot:"label"},[_vm._v(" Impact Score "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"impact_score","name":"Impact Score","rules":{
              required: true,
              positive: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.impactOptions},model:{value:(_vm.formData.residual_impact_score),callback:function ($$v) {_vm.$set(_vm.formData, "residual_impact_score", $$v)},expression:"formData.residual_impact_score"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Likelihood"}},[_c('template',{slot:"label"},[_vm._v(" Likelihood "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"likelihood_value","name":"Likelihood","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.likelihoodOptions},model:{value:(_vm.formData.residual_likelihood_value),callback:function ($$v) {_vm.$set(_vm.formData, "residual_likelihood_value", $$v)},expression:"formData.residual_likelihood_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',[_c('validation-provider',{attrs:{"vid":"should_update_related_risk","name":"Should Update Related Risk","rules":{
              required: false,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"custom-control-warning",model:{value:(_vm.formData.should_update_related_risk),callback:function ($$v) {_vm.$set(_vm.formData, "should_update_related_risk", $$v)},expression:"formData.should_update_related_risk"}},[_vm._v("Apply this risk value for all risk register related entries. ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-end py-50"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Submit")])],1),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.closeAction}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Cancel")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }