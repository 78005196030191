export default {
  name: "RiskMixins",
  methods: {
    getRiskRegisterDetails(registerId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/risks/register/${registerId}`,
        };

        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getRiskRegisterTasks(registerId, params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/risks/register/${registerId}/tasks`,
          params: params,
        };

        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateResidualRiskValues(registerId, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (data[key] && data[key] != null) {
            formData.append(key, data[key]);
          }
        });
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/risks/register/${registerId}/update-risk-values`,
          data: formData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    bulkUpdateRiskControls(riskId, data) {
      console.log("Data", data);
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (data[key] && data[key] != null) {
            if (key == "control_ids") {
              data[key].forEach((cId, i) => {
                formData.append([`${key}[${i}]`], cId);
              });
            } else {
              formData.append(key, data[key]);
            }
          }
        });
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/risks/${riskId}/risk-controls/update-multiple`,
          data: formData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    updateRiskControl(riskId, controlId, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (data[key] && data[key] != null) {
            formData.append(key, data[key]);
          }
        });
        const options = {
          method: "POST",
          url: `${process.env.VUE_APP_BASEURL}/risks/${riskId}/risk-controls/${controlId}`,
          data: formData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    getRiskControls(riskId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/risks/${riskId}/risk-controls`,
        };

        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getRiskDiscussions(riskId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/risks/register/${riskId}/comments`,
        };

        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    createRiskComment(riskId, message) {
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        bodyFormData.append("message", message);
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/risks/register/${riskId}/add-comment`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    updateRiskImpactAndLikelihoodRelation(
      impactValue,
      likelihoodValue,
      riskId,
      assetId
    ) {
      return new Promise((resolve, reject) => {
        var params = new URLSearchParams();
        if (impactValue) {
          params.append("impact_value", impactValue);
        } else {
          params.append("impact_value", 1);
        }
        if (likelihoodValue) {
          params.append("likelihood_value", likelihoodValue);
        } else {
          params.append("likelihood_value", 1);
        }
        const options = {
          method: "PUT",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/${riskId}/update`,
          data: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateResidualRiskScore(
      impactValue,
      likelihoodValue,
      residualRiskValue,
      riskId,
      assetId
    ) {
      return new Promise((resolve, reject) => {
        var params = new URLSearchParams();
        if (impactValue) {
          params.append("impact_value", impactValue);
        } else {
          params.append("impact_value", 1);
        }
        if (likelihoodValue) {
          params.append("likelihood_value", likelihoodValue);
        } else {
          params.append("likelihood_value", 1);
        }
        if (residualRiskValue) {
          params.append("residual_risk_value", residualRiskValue);
        } else {
          params.append("residual_risk_value", 1);
        }
        const options = {
          method: "PUT",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/${riskId}/update`,
          data: params,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAssetRiskRelation(assetId, riskId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/${riskId}`,
        };

        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getAllRisksRegisters(params) {
      const newParams = {};
      Object.keys(params).map((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== ""
        ) {
          newParams[key] = params[key];
        }
      });
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/risks/register`,
          params: newParams,
        };
        this.$http(options)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAllRisksBySearchPhrase(searchPhrase) {
      return new Promise((resolve, reject) => {
        if ((searchPhrase !== null) | (searchPhrase !== "")) {
          const options = {
            method: "GET",
            url: `${process.env.VUE_APP_BASEURL}/risks`,
            params: {
              search: searchPhrase,
            },
          };
          this.$http(options)
            .then((resp) => {
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },

    getRisks(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/risks`,
          params: params,
        };
        this.$http(options)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getRiskDetails(id) {
      return new Promise((resolve, reject) => {
        if (id !== null) {
          const options = {
            method: "GET",
            url: `${process.env.VUE_APP_BASEURL}/risks/${id}`,
          };
          this.$http(options)
            .then((resp) => {
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    createNewCustomRisk(assetId, risk, riskName, threat, vulnerabilities) {
      return new Promise((resolve, reject) => {
        console.log("Risk", risk);
        console.log("threat", threat);
        console.log("vulnerabilities", vulnerabilities);

        const bodyFormData = new FormData();

        if (risk && risk !== null) {
          if (risk.is_new != true) {
            bodyFormData.append(`risk[is_new]`, 0);
            bodyFormData.append(
              `risk[risk_id]`,
              `${risk.risk_id ? risk.risk_id : ""}`
            );
          } else {
            bodyFormData.append(`risk[is_new]`, 1);
            bodyFormData.append(`risk[risk_name]`, riskName);
          }
        }

        if (threat.is_new !== null) {
          bodyFormData.append(`threat[is_new]`, `${threat.is_new ? 1 : 0}`);
        }

        if (threat.threat_name) {
          bodyFormData.append(`threat[threat_name]`, threat.threat_name);
        }

        if (!threat.is_new || (threat.is_new == false && threat.threat_id)) {
          bodyFormData.append(`threat[threat_id]`, threat.threat_id);
        }

        // threats.map((threat, i) => {
        //     bodyFormData.append(`threat[${i}][is_new]`, `${threat.is_new ? 1 : 0}`)
        //     if (threat.is_new === true) {
        //         bodyFormData.append(`threats[${i}][threat_name]`, `${threat.threat_name}`)
        //     }
        //     bodyFormData.append(`threats[${i}][threat_id]`, `${threat.threat_id ? threat.threat_id : ''}`)
        // })

        vulnerabilities.map((vul, i) => {
          bodyFormData.append(
            `vulnerabilities[${i}][is_new]`,
            `${vul.is_new ? 1 : 0}`
          );
          if (vul.is_new === true) {
            bodyFormData.append(
              `vulnerabilities[${i}][vulnerability_name]`,
              `${vul.vulnerability_name}`
            );
          }
          bodyFormData.append(
            `vulnerabilities[${i}][vulnerability_id]`,
            `${vul.vulnerability_id ? vul.vulnerability_id : ""}`
          );
        });
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/custom-map`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    createCustomRiskV2(assetId, obj) {
      return new Promise((resolve, reject) => {
        console.log("OBJ", obj);
        const bodyFormData = new FormData();

        Object.keys(obj).map((key) => {
          if (obj[key] && obj[key] != null && obj[key] != "") {
            if (key == "risk") {
              if (typeof obj[key] == "string") {
                bodyFormData.append(`risk[is_new]`, 1);
                bodyFormData.append(`risk[risk_name]`, obj[key]);
              } else {
                bodyFormData.append(`risk[is_new]`, 0);
                bodyFormData.append(`risk[risk_id]`, `${obj[key].value}`);
              }
            } else if (key == "threat") {
              if (typeof obj[key] == "string") {
                bodyFormData.append(`threat[is_new]`, 1);
                bodyFormData.append(`threat[threat_name]`, obj[key]);
                bodyFormData.append(
                  `threat[threat_value]`,
                  obj["threat_value"]
                );
              } else {
                bodyFormData.append(`threat[is_new]`, 0);
                bodyFormData.append(`threat[threat_id]`, `${obj[key].value}`);
              }
            } else if (key == "vulnerabilities") {
              obj[key].map((vul, i) => {
                if (vul.value && vul.value != null) {
                  bodyFormData.append(`vulnerabilities[${i}][is_new]`, 0);
                  bodyFormData.append(
                    `vulnerabilities[${i}][vulnerability_name]`,
                    `${vul.name}`
                  );
                  bodyFormData.append(
                    `vulnerabilities[${i}][vulnerability_id]`,
                    vul.value
                  );
                } else {
                  bodyFormData.append(`vulnerabilities[${i}][is_new]`, 1);
                  bodyFormData.append(
                    `vulnerabilities[${i}][vulnerability_value]`,
                    vul.vulnerability_value
                  );
                  bodyFormData.append(
                    `vulnerabilities[${i}][vulnerability_name]`,
                    `${vul.name}`
                  );
                }
              });
            }
          }
        });

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/custom-map`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    createRiskAction(obj) {
      return new Promise((resolve, reject) => {
        const riskId = obj.risk_id;
        const bodyFormData = new FormData();
        bodyFormData.append("note", obj.note);

        bodyFormData.append("type", obj.type);
        if (obj.task && obj.task.taskAssignee) {
          bodyFormData.append("task_title", obj.task.taskTitle);
          bodyFormData.append("task_assignee", obj.task.taskAssignee.user_id);
          bodyFormData.append("task_due_date", obj.task.taskDueDate);
          bodyFormData.append("task_priority", obj.task.taskPriority);
          bodyFormData.append("task_description", obj.task.taskDescription);
          bodyFormData.append("risk_register_id", riskId);
        }
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/risks/register/${riskId}/actions`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    createBulkRiskAction(obj) {
      console.log("Bulk action OBJ ", obj);
      return new Promise((resolve, reject) => {
        const bodyFormData = new FormData();
        // bodyFormData.append("_method", "PUT");
        bodyFormData.append("note", obj.note);
        bodyFormData.append("type", obj.type);

        if (obj.task && obj.task != null && obj.task.taskTitle != null) {
          bodyFormData.append("task_title", obj.task.taskTitle);
          bodyFormData.append("task_assignee", obj.task.taskAssignee.user_id);
          bodyFormData.append("task_due_date", obj.task.taskDueDate);
          bodyFormData.append("task_priority", obj.task.taskPriority);
          bodyFormData.append("task_description", obj.task.taskDescription);
        }

        if (obj.risk_ids && obj.risk_ids != null && obj.risk_ids.length > 0) {
          obj.risk_ids.forEach((id, index) => {
            bodyFormData.append(`risk_register_ids[${index}]`, id);
          });
        }

        // if (
        //   obj.risk_cat_scores &&
        //   obj.risk_cat_scores != null &&
        //   obj.risk_cat_scores.length > 0
        // ) {
        //   obj.risk_cat_scores.map((id, i) => {
        //     const data = id.split("_");
        //     bodyFormData.append(`risks[${i}][risk_id]`, data[0]);
        //     bodyFormData.append(`risks[${i}][asset_category_id]`, data[1]);
        //     bodyFormData.append(`risks[${i}][risk_value]`, data[2]);
        //   });

        //   const options = {
        //     method: "POST",
        //     headers: { "content-type": "application/x-www-form-urlencoded" },
        //     url: `${process.env.VUE_APP_BASEURL}/risks/action`,
        //     data: bodyFormData,
        //   };
        //   this.$http(options)
        //     .then((res) => resolve(res))
        //     .catch((err) => reject(err));
        // } else {

        if (obj.filters) {
          if (
            obj.filters.search &&
            obj.filters.search != null &&
            obj.filters.search != ""
          ) {
            bodyFormData.append("risk_search[search]", obj.filters.search);
          }
          if (
            obj.filters.max_risk_value &&
            obj.filters.max_risk_value != null
          ) {
            bodyFormData.append(
              "risk_search[max_risk_value]",
              obj.filters.max_risk_value
            );
          }
          if (
            obj.filters.max_risk_residual_score &&
            obj.filters.max_risk_residual_score != null
          ) {
            bodyFormData.append(
              "risk_search[max_residual_risk_value]",
              obj.filters.max_risk_residual_score
            );
          }
          if (obj.filters.risk_category && obj.filters.risk_category != null) {
            bodyFormData.append(
              "risk_search[risk_category]",
              obj.filters.risk_category
            );
          }
          if (
            obj.filters.risk_mitigation_type &&
            obj.filters.risk_mitigation_type != null
          ) {
            bodyFormData.append(
              "risk_search[risk_mitigation_type]",
              obj.filters.risk_mitigation_type
            );
          }
        }

        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/risks/register/actions`,
          data: bodyFormData,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    getRiskActionTasks(user_id) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/risks/action/tasks`,
          params: {
            user_id: user_id,
          },
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    repopulateRisks(assetId) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/repopulate`,
        };
        this.$http(options)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      });
    },

    //----------------Threats-------------------------

    getThreats(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/threats`,
          params: params,
        };
        this.$http(options)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getAllThreatsBySearchPhrase(searchPhrase) {
      return new Promise((resolve, reject) => {
        if ((searchPhrase !== null) | (searchPhrase !== "")) {
          const options = {
            method: "GET",
            url: `${process.env.VUE_APP_BASEURL}/threats`,
            params: {
              search: searchPhrase,
            },
          };
          this.$http(options)
            .then((resp) => {
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },

    //----------------Vulnerabilities-------------------------
    getVulnerabilities(params) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/vulnerabilities`,
          params: params,
        };
        this.$http(options)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAllVulnerabilitiesBySearchPhrase(searchPhrase) {
      return new Promise((resolve, reject) => {
        if ((searchPhrase !== null) | (searchPhrase !== "")) {
          const options = {
            method: "GET",
            url: `${process.env.VUE_APP_BASEURL}/vulnerabilities`,
            params: {
              search: searchPhrase,
            },
          };
          this.$http(options)
            .then((resp) => {
              resolve(resp);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
  },
};
