<template>
  <b-badge style="min-width: 120px" :variant="getVariant(value)"
    >{{ getTextValue(value) }}
    <b-badge pill variant="dark" class="ml-25" size="sm">{{ value }}</b-badge></b-badge
  >
</template>

<script>
import { BBadge } from "bootstrap-vue";
export default {
  components: {
    BBadge,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    getVariant: {
      type: Function,
      required: false,
      default:()=>'light-primary'
    },
    getTextValue: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style>
</style>