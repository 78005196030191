<template>
  <div
    class="w-100 h-100 d-flex flex-column alin-items-center justify-content-start"
  >
    <div
      class="w-100 d-flex align-items-center justify-content-between border-bottom px-2 py-1"
    >
      <h4
        class="mb-0 font-weight-bold align-center text-truncate"
        style="max-width: 70%"
      >
        {{ title }}
      </h4>
      <b-button size="sm" variant="flat-dark" @click="handleCloseClick">
        <feather-icon size="20" icon="XIcon" />
      </b-button>
    </div>
    <div class="w-100 px-2 pt-2 pb-2 position-relative" style="min-height: 100%">
      <slot name="content"> </slot>
    </div>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BButton,
    VuePerfectScrollbar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        minScrollbarLength: 60,
      },
    };
  },
  methods: {
    handleCloseClick() {
      this.closeAction();
    },
  },
};
</script>

<style></style>
