<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="w-100 h-auto">
      <div class="px-25 pt-0 pb-0">
        <div class="pb-25 pt-0 w-100">
          <div class="app-fixed-search w-100">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon
                  v-if="filters.search == null || filters.search == ''"
                  icon="SearchIcon"
                  class="text-muted"
                />
                <feather-icon
                  @click="
                    () => {
                      filters.search = null;
                    }
                  "
                  v-else
                  icon="XIcon"
                  class="text-danger cursor-pointer"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="filters.search"
                placeholder="Search..."
                debounce="500"
                size="md"
              />
            </b-input-group>
          </div>
        </div>
      </div>
      <table role="table" class="table table-hover table-condensed w-100">
        <thead role="rowgroup">
          <tr role="row">
            <th role="columnheader" scope="col"></th>
            <th role="columnheader" scope="col">Name</th>
            <th role="columnheader" scope="col">Due Date</th>
            <th role="columnheader" scope="col">Assignee</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="tasks && tasks != null && tasks.length > 0">
            <template v-for="(task, i) in tasks">
              <tr
                role="row"
                :key="task._id"
                @click="
                  () => {
                    $router.push({
                      name: 'tasks',
                      query: {
                        task_id: task._id,
                      },
                    });
                  }
                "
              >
                <td role="cell">
                  {{ i + 1 }}
                </td>
                <td role="cell" style="max-width: 30vw">
                  {{ task.title }}
                </td>

                <td role="cell" style="max-width: 20vw">
                  {{ task.due_date | moment }}
                </td>
                <td role="cell" style="max-width: 20vw">
                  <template
                    v-if="task.assignee_user && task.assignee_user != null"
                  >
                    {{
                      `${task.assignee_user.firstname} ${task.assignee_user.lastname}`
                    }}
                  </template>
                  <b-badge v-else variant="danger">Deleted User</b-badge>
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <empty-table-section title="Tasks Empty">
              <template #content>
                <p class="text-center">
                  It seems there are no tasks at the moment.
                </p>
              </template>
            </empty-table-section>
          </template>
        </tbody>
      </table>
      <div
        class="w-100 card-body"
        v-if="tasks && tasks != null && tasks.length > 0"
      >
        <div
          class="demo-spacing-0 d-flex align-items-center justify-content-between"
        >
          <p class="align-middle mb-0">
            Showing {{ from }} to {{ to }} of {{ total }} entries
          </p>

          <b-pagination
            v-model="filters.page"
            :total-rows="total"
            :per-page="10"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import moment from "moment";
import {
  BOverlay,
  BBadge,
  BPagination,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";
import RiskMixins from "@/mixins/RiskMixins";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
import ResponseMixins from "@/mixins/ResponseMixins";

export default {
  mixins: [RiskMixins, ResponseMixins],
  components: {
    BOverlay,
    BBadge,
    EmptyTableSection,
    BPagination,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },

  props: {
    registerId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showOverlay: false,
      tasks: [],

      total: 1,
      from: 1,
      to: 1,
      filters: {
        search: null,
        page: 1,
      },
    };
  },

  watch: {
    filters: {
      handler: function (newValues) {
        this.getRiskTasksAndSetData(this.registerId, newValues);
      },
      deep: true,
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getRiskTasksAndSetData(this.registerId, this.filters);
    },

    getRiskTasksAndSetData(registerId, params) {
      this.showOverlay = true;
      this.getRiskRegisterTasks(registerId, params)
        .then((res) => {
          console.log("Risk Tasks: ", res);
          this.tasks = res.data.data.data;
          this.total = res.data.data.total;
          this.from = res.data.data.from;
          this.to = res.data.data.to;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style>
</style>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>
