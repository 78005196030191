<template>
  <b-overlay
    opacity="0.9"
    blur="1rem"
    :show="isUserSuggestionLoading"
    rounded="md"
    variant="secondary"
    class="w-100"
  >
    <template #overlay>
      <div class="text-center">
        <b-spinner label="Loading..." class="mb-25" />
        <p class="font-weight-bold mb-0">Please wait...</p>
      </div>
    </template>
    <div class="mention-input" :style="d_mentionInputStyles">
      <div :class="d_containerClass" :style="d_containerStyles">
        <div :class="d_mainContainerClass" :style="d_mainContainerStyles">
          <div class="mention-input__input-container" @focus="handleFocus">
            <vue-editor
              ref="editor"
              class="mention-input__input-item-container relative"
              :editorToolbar="customToolbar"
              :editorOptions="d_editorOptions"
              @focus="handleFocus"
              @input="handleInput"
              @blur="handleBlur"
              v-model="value"
              :placeholder="placeholder"
            >
            </vue-editor>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { VueEditor } from "vue2-editor";
import "quill-mention";
import _ from "lodash";
import ResponseMixins from "@/mixins/ResponseMixins";
import UtilsMixins from "@/mixins/UtilsMixins";
import UserMixins from "@/mixins/UserMixins";
import { BOverlay, BSpinner } from "bootstrap-vue";
// const hashValues = [
//   { id: 3, value: "Speaker: Michael Shill" },
//   { id: 4, value: "Speaker: Bill Clinton" },
//   { id: 3, value: "Speaker: Herman Melville" },
//   { id: 4, value: "Sponsor: Nike" },
// ];
function isNode(v, title = "GLOBALS.NODE.ISNODE") {
  if (v instanceof Element) return true;
  return !!console.error(new Error(`ERR::${title}! ${v} is not a node`));
}

function componentBlurred(container) {
  if (!isNode(container, "GLOBALS.EVENT.COMPONENTBLURRED")) return false;
  if (!container.contains(document.activeElement)) return true;
  return false;
}

function checkItemClickedForBlur() {
  if (componentBlurred(this.$el)) {
    this.d_focus = false;
    this.$emit("input", { value: this.value, mentions: this.d_mentions });
    this.$emit("blur");
  }
}

export default {
  name: "mentionInput",
  components: { VueEditor, BOverlay, BSpinner },
  mixins: [ResponseMixins, UtilsMixins, UserMixins],
  data() {
    return {
      d_focus: false,
      d_setvalue: !!this.value,
      d_mentions: [],
      value: "",
      placeholder: "",
      inline: true,
      slim: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"],
      ],

      isUserSuggestionLoading: false,
    };
  },
  props: {},
  computed: {
    d_editorOptions() {
      const { d_atValues, getUserList } = this;
      return {
        theme: null,
        modules: {
          toolbar: false,
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ["@"],
            source: async function (searchTerm, renderList, mentionChar) {
              // const values = await getUserSuggestions(searchTerm);
              if (mentionChar === "@") {
                getUserList(searchTerm).then((res) => {
                  const values = res;
                  if (values && values != null && values.length > 0) {
                    renderList(values, searchTerm);
                  }
                });
              }

              // if (searchTerm.length === 0) {
              //   renderList(values, searchTerm);
              // } else if(values && values!=null && values.length>0) {
              //   const matches = [];
              //   for (let i = 0; i < values.length; i++)
              //     if (
              //       ~values[i].value
              //         .toLowerCase()
              //         .indexOf(searchTerm.toLowerCase())
              //     )
              //       matches.push(values[i]);
              //   renderList(matches, searchTerm);
              // }
            },
          },
        },
      };
    },

    d_atValues() {
      return [
        { id: 1, value: "Chris Sjoblom" },
        { id: 2, value: "Fred Poust" },
        { id: 3, value: "Employee TBD" },
        { id: 4, value: "Employee TBD2" },
      ];
    },
    d_textAreaPadding() {
      const setValue = this.d_setvalue ? "1px" : null;
      return this.d_focus ? "2px" : setValue;
    },
    d_mainContainerClass() {
      const { inline, slim } = this;
      const out = [`mention-input__main-container`];
      if (inline) {
        out.push(`mention-input--inline`);
      }
      if (slim) {
        out.push(`mention-input__main-container--slim`);
      }
      return out;
    },
    d_mentionInputStyles() {
      return {
        zIndex: this.d_focus ? 1 : 0,
        paddingBottom: this.d_textAreaPadding,
      };
    },
    d_containerClass() {
      const container = [`mention-input__container`];
      if (this.d_focus) {
        const focusClass = this.slim
          ? `mention-input__container--focused-slim`
          : `mention-input__container--focused`;
        container.push(focusClass);
      }
      return container;
    },
    d_containerStyles() {
      return { marginBottom: this.d_focus || this.d_setvalue ? "-1px" : null };
    },
    d_mainContainerStyles() {
      return {
        paddingBottom: this.padded ? `${this.padded}px` : null,
        fontWeight: this.bolded ? "600" : null,
      };
    },
  },
  methods: {
    clearText() {
      this.value = "";
    },

    async getUserList(search = null) {
      this.isUserSuggestionLoading = true;
      return new Promise((resolve, reject) => {
        this.getUsers({ search: search, limit: 5 })
          .then((res) => {
            const users = res.data.data.data.map((u) => {
              return {
                id: u.email,
                // value: `{{${u.email}}}`,
                value: `${u.firstname} ${u.lastname}`,
              };
            });
            // console.log("Search Result", users);
            resolve(users);
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
            reject(err);
          })
          .finally(() => {
            this.isUserSuggestionLoading = false;
          });
      });
    },

    async getUserSuggestions(search = null) {
      this.isUserSuggestionLoading = true;
      this.getUsers({ search: search, limit: 5 })
        .then((res) => {
          const users = res.data.data.data.map((u) => {
            return {
              id: u.email,
              // value: `{{${u.email}}}`,
              value: `${u.firstname} ${u.lastname}`,
            };
          });

          // console.log("Search Result", users);

          return users;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.isUserSuggestionLoading = false;
        });
    },

    setFocus() {
      this.d_focus = true;
      this.$nextTick(() => {
        if (this.$refs && this.$refs.editor && this.$refs.editor.quill) {
          this.$refs.editor.quill.focus();
        }
      });
    },
    handleFocus(e) {
      if (this.$el && !this.$el.contains(document.activeElement))
        this.$emit("focus");
      const { target } = e;
      this.d_focus = true;
      // if(!this.d_setvalue) this.d_computedheight = getNearestSpacing(this.height);
      this.$nextTick(() => {
        if (
          target !== this.$refs.textarea &&
          this.$refs &&
          this.$refs.editor &&
          this.$refs.editor.quill
        ) {
          this.$refs.editor.quill.focus();
        }
      });
    },
    getMentions: function () {
      // Dummy example to get all the mentions.
      var marvelDelta = this.$refs.editor.quill.editor.delta;
      var mentionDelta = _.filter(marvelDelta.ops, "insert.mention"); // lodash
      var mentions = _.map(mentionDelta, function (value) {
        return _.toInteger(_.get(value, "insert.mention.value"));
      });
      return mentions;
    },
    handleBlur() {
      setTimeout(checkItemClickedForBlur.bind(this), 0);
    },
    handleInput(v) {
      if (!v) return;
      this.$nextTick(() => {
        this.$emit("input", { value: this.value, mentions: this.d_mentions });
      });
    },
  },
  watch: {
    value: {
      handler(v) {
        if (!v) return;
        this.$nextTick(() => {
          const { editor } = this.$refs;
          if (editor) {
            this.d_mentions = this.getMentions();
          }
        });
      },
    },
  },
};
</script>

<style lang="scss">
.relative {
  position: relative;
}
.hide-toolbar > .ql-toolbar {
  display: none !important;
}

.mention {
  background: #5b38bd;
  color: white;
  border-radius: 10rem;
  padding: 0.3rem 0.5rem;
  margin: 2px auto;
  text-align: center;
  display: inline-block;
  font-size: 85%;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    background 0s, border 0s, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, background 0s, border 0s,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
    border 0s, -webkit-box-shadow 0.15s ease-in-out;
}

.mention-input__input-item-container {
  width: 100%;
  .ql-container {
    border: none !important;

    /* border-radius: 5px; */

    .ql-mention-list-container {
      background: white !important;
      border-radius: 8px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

      ul.ql-mention-list {
        list-style: none;
        margin: 0;
        padding: 12px 14px;

        li {
          display: flex;
          align-items: center;
          padding: 0.75rem 1rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1rem;
          background-color: transparent;
          border-radius: 8px;
          cursor: pointer;
          transition: background-color 0.3s ease-in-out;
          color: #6e6b7b;

          &:hover {
            background-color: #5b38bd; // Slightly lighter background on hover
            color: white;
          }

          &.highlighted {
            background-color: #5b38bd; // Background for the selected suggestion
            color: white;
            border: 1px solid #f6f6f6;
          }

          .user-icon {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #6a6a6e; // Placeholder color for the user icon
            margin-right: 8px;
          }

          .username {
            flex: 1;
            font-size: 14px;
            color: #fff;

            .highlight {
              color: #f0c040; // Color for the highlighted text
            }
          }

          .tag {
            font-size: 12px;
            color: #999; // Tag color
            margin-left: 8px;
          }
        }
      }
    }

    .ql-editor {
      min-height: 3em !important;
      padding: 0 !important;
      margin-top: 0 !important;
      line-height: 1.6rem;
    }
    .ql-editor.ql-blank::before {
      font-style: normal !important;
      left: 0;
      color: darkgrey !important;
    }
  }
  .ql-toolbar {
    border-width: 0 0 1px 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: -8px;
  }
}
.mention-input__main-container--slim {
  .mention-input__input-item-container {
    .ql-toolbar {
      margin-top: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.mention-input {
  margin: 0;
  position: relative;
  width: 100%;

  &__container {
    width: 100%;
    background: white;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    padding: 0.8rem 1rem !important;
  }

  &--inline {
    /*border-bottom: solid 1px darkgrey;*/
    /* padding-top: (16px - 1px);
      padding-bottom: 16px; */
  }

  &__main-container {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    height: 100%;
    cursor: pointer;
    margin: 0px 8px;
    -webkit-transition: height 0.1s; /* Safari */
    transition: height 0.1s;

    &--readonly {
      margin: 0 !important;
    }

    &--slim {
      border-bottom: none;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__icon {
    color: grey;
    width: 18px;
    height: 18px;
    font-size: 18px;
    margin-right: 8px;
  }

  &__icon.busy {
    color: blue;
  }

  &__placeholder {
  }

  &__placeholder:focus {
    outline: none;
  }

  &__icon:focus {
    outline: none;
  }

  &__label:focus {
    outline: none;
  }

  &__chevron:focus {
    outline: none;
  }

  &__input {
    border: none;
    font-size: 16px;
    text-align: left;
    margin-right: -1px;
    width: 100%;
    height: 100%;
    resize: none;
    color: grey;
    background: transparent;
    padding: 0;
    margin: 0;

    &-container {
      width: 100%;
      height: 100%;
    }

    &-item-container {
      height: 100%;
    }
  }

  &__input:focus {
    outline: none;
  }

  &--disabled {
    opacity: 0.8 !important;

    & .mention-input__icon {
      color: darkgrey !important;
    }

    & .mention-input__label {
      color: darkgrey !important;
    }

    & .mention-input__input {
      color: darkgrey !important;
      opacity: 1 !important;
    }

    & .mention-input__placeholder {
      color: darkgrey !important;
    }
  }
}

.mention-input__container--focused {
  box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  background-color: #fff;
  border-color: #5b38bd;
  .mention-input__input {
    cursor: text;
  }

  .mention-input--inline {
    border-bottom: none;
  }
}

.mention-input__container--focused--slim {
  background-color: "#fafcff";
  .mention-input__input {
    cursor: text;
  }

  .mention-input--inline {
    border-bottom: none;
  }
}

.dark-layout {
  .mention-input {
    &__container {
      background: #0e081c;
      border: 1px solid #3b4253;
    }
  }

  .mention-input__container--focused {
    border-color: #5b38bd;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
  }
}
</style>
