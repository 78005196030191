<template>
  <div class="w-100 h-100">
    <!-- <sidebar-template title="Adjust Residual Risk" :closeAction="closeAction">
      <template #content> </template>
    </sidebar-template> -->
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <validation-observer ref="risk_assessment_form" #default="{ invalid }">
        <b-form class="mt-0" @submit.prevent="handleSubmitClick">
          <b-form-group label="Impact Score">
            <template slot="label">
              Impact Score <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              vid="impact_score"
              name="Impact Score"
              :rules="{
                required: true,
                positive: true,
              }"
            >
              <b-form-select
                v-model="formData.residual_impact_score"
                :options="impactOptions"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Likelihood">
            <template slot="label">
              Likelihood <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              vid="likelihood_value"
              name="Likelihood"
              :rules="{
                required: true,
              }"
            >
              <b-form-select
                v-model="formData.residual_likelihood_value"
                :options="likelihoodOptions"
              ></b-form-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              vid="should_update_related_risk"
              name="Should Update Related Risk"
              :rules="{
                required: false,
              }"
            >
              <b-form-checkbox
                v-model="formData.should_update_related_risk"
                class="custom-control-warning"
                >Apply this risk value for all risk register related entries.
              </b-form-checkbox>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div
            class="w-100 d-flex align-items-center justify-content-end py-50"
          >
            <b-button
              class="mr-1"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Submit</span>
            </b-button>

            <b-button @click="closeAction" variant="outline-secondary">
              <feather-icon icon="XIcon" class="mr-50" />
              <span class="align-middle">Cancel</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BOverlay,
  BFormGroup,
  BFormInput,
  BForm,
  BBadge,
  BFormSelect,
  BFormCheckbox,
} from "bootstrap-vue";
import SidebarTemplate from "@/components/SidebarTemplate.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import RiskMixins from "@/mixins/RiskMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
export default {
  mixins: [RiskMixins, ResponseMixins],
  components: {
    BButton,
    SidebarTemplate,
    BOverlay,
    BFormGroup,
    BFormInput,
    BForm,
    BBadge,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    required,
    BFormCheckbox,
  },

  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      showOverlay: false,
      impactOptions: [
        // { value: null, text: "Select a value" },
        { value: 1, text: "Very Low" },
        { value: 2, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 4, text: "High" },
        { value: 5, text: "Critical" },
      ],

      likelihoodOptions: [
        // { value: null, text: "Select a value.." },
        { value: 1, text: "Once 5 years" },
        { value: 2, text: "Once 2 years" },
        { value: 3, text: "Once a year" },
        { value: 4, text: "Once in Six Months" },
        { value: 5, text: "Once Month" },
      ],

      formData: {
        residual_impact_score: null,
        residual_likelihood_value: null,
        should_update_related_risk: false,
      },
      registerDetails: null,
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.getRiskRegisterDetailsAndSetData(this.$route.params.riskId);
    },

    handleSubmitClick() {
      const newData = this.formData;
      this.updateResidualRiskValuesAndSetData(
        this.$route.params.riskId,
        newData
      );
    },

    updateResidualRiskValuesAndSetData(registerId, data) {
      this.showOverlay = true;
      this.updateResidualRiskValues(registerId, data)
        .then((res) => {
          console.log("Risk Register Updated: ", res);
          this.handleResponse(res);
          this.reloadParent();
          this.closeAction();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getRiskRegisterDetailsAndSetData(registerId) {
      this.showOverlay = true;
      this.getRiskRegisterDetails(registerId)
        .then((res) => {
          console.log("Register Details: ", res);
          const register = res.data.data;
          this.registerDetails = register;
          if (
            register.residual_impact_score &&
            register.residual_impact_score != null
          ) {
            this.$set(
              this.formData,
              "residual_impact_score",
              register.residual_impact_score
            );
          }
          if (
            register.residual_likelihood_value &&
            register.residual_likelihood_value != null
          ) {
            this.$set(
              this.formData,
              "residual_likelihood_value",
              register.residual_likelihood_value
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style>
</style>