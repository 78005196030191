var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":"0.17","blur":"1rem","show":_vm.showOverlay,"rounded":"md","variant":"secondary"}},[_c('div',{staticClass:"w-100 h-auto"},[_c('div',{staticClass:"px-25 pt-0 pb-0"},[_c('div',{staticClass:"pb-25 pt-0 w-100"},[_c('div',{staticClass:"app-fixed-search w-100"},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[(_vm.filters.search == null || _vm.filters.search == '')?_c('feather-icon',{staticClass:"text-muted",attrs:{"icon":"SearchIcon"}}):_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":function () {
                    _vm.filters.search = null;
                  }}})],1),_c('b-form-input',{attrs:{"placeholder":"Search...","debounce":"500","size":"md"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)],1)])]),_c('table',{staticClass:"table table-hover table-condensed w-100",attrs:{"role":"table"}},[_c('thead',{attrs:{"role":"rowgroup"}},[_c('tr',{attrs:{"role":"row"}},[_c('th',{attrs:{"role":"columnheader","scope":"col"}}),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Name")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Due Date")]),_c('th',{attrs:{"role":"columnheader","scope":"col"}},[_vm._v("Assignee")])])]),_c('tbody',[(_vm.tasks && _vm.tasks != null && _vm.tasks.length > 0)?[_vm._l((_vm.tasks),function(task,i){return [_c('tr',{key:task._id,attrs:{"role":"row"},on:{"click":function () {
                  _vm.$router.push({
                    name: 'tasks',
                    query: {
                      task_id: task._id,
                    },
                  });
                }}},[_c('td',{attrs:{"role":"cell"}},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('td',{staticStyle:{"max-width":"30vw"},attrs:{"role":"cell"}},[_vm._v(" "+_vm._s(task.title)+" ")]),_c('td',{staticStyle:{"max-width":"20vw"},attrs:{"role":"cell"}},[_vm._v(" "+_vm._s(_vm._f("moment")(task.due_date))+" ")]),_c('td',{staticStyle:{"max-width":"20vw"},attrs:{"role":"cell"}},[(task.assignee_user && task.assignee_user != null)?[_vm._v(" "+_vm._s(((task.assignee_user.firstname) + " " + (task.assignee_user.lastname)))+" ")]:_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("Deleted User")])],2)])]})]:[_c('empty-table-section',{attrs:{"title":"Tasks Empty"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',{staticClass:"text-center"},[_vm._v(" It seems there are no tasks at the moment. ")])]},proxy:true}])})]],2)]),(_vm.tasks && _vm.tasks != null && _vm.tasks.length > 0)?_c('div',{staticClass:"w-100 card-body"},[_c('div',{staticClass:"demo-spacing-0 d-flex align-items-center justify-content-between"},[_c('p',{staticClass:"align-middle mb-0"},[_vm._v(" Showing "+_vm._s(_vm.from)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries ")]),_c('b-pagination',{attrs:{"total-rows":_vm.total,"per-page":10,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,false,1308952388),model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }